import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import dateFormat from 'dateformat'
import { StaticImage } from 'gatsby-plugin-image'
import TagsTopicList from 'components/TagsTopicList'

export const query = graphql`
  query BlogPostByID($id: String!) {
    file(relativePath: { eq: "mihirpipermitwala-placeholder.jpg" }) {
      childImageSharp {
        resize {
          height
          width
        }
      }
      ext
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM DD, YYYY")
        }
      }
      frontmatter {
        publishedAt(formatString: "MMMM DD, YYYY")
        title
        description
        topics
        keywords
        showDefaultImage
      }
    }
  }
`

const Post = ({ data, location }) => {
  const { markdownRemark: post } = data
  const disqusConfig = {
    url: `${data.site.siteMetadata.siteUrl + location.pathname}`,
    identifier: post.id,
    title: post.frontmatter.title,
  }
  const topics = post.frontmatter.topics

  return (
    <IntlProvider pathname={location.pathname}>
      {({ formatDate, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={post.frontmatter.locale}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            keywords={post.frontmatter.keywords.join(', ')}
            isBlogPost={true}
            ogTitle={post.frontmatter.title}
            ogUrl={data.site.siteMetadata.siteUrl + post.fields.slug}
            ogImageUrl={data.site.siteMetadata.siteUrl + data.file.publicURL}
            ogImageSecureUrl={data.site.siteMetadata.siteUrl + data.file.publicURL}
            ogImageAlt={post.fields.slug}
            ogImageWidth={data.file.childImageSharp.resize.width}
            ogImageHeight={data.file.childImageSharp.resize.height}
            ogImageType={data.file.ext === '.jpg' ? 'image/jpg' : 'image/png'}
            ogDescription={post.frontmatter.description}
            ogKeywords={post.frontmatter.keywords.toString()}
            ogLocale={post.frontmatter.locale}
            twitterTitle={post.frontmatter.title}
            twitterDescription={post.frontmatter.description}
            twitterCreator={data.site.siteMetadata.title}
            siteName={data.site.siteMetadata.title}
            datePublished={dateFormat(post.frontmatter.publishedAt, 'yyyy-mm-dd')}
            dateModified={dateFormat(post.parent.modifiedTime, 'yyyy-mm-dd')}
          />
          <article className='post'>
            <h1 className='post__title'>{post.frontmatter.title}</h1>
            {post.frontmatter.publishedAt && (
              <time dateTime={post.frontmatter.publishedAt} className='post__time'>
                {formatDate(post.frontmatter.publishedAt, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}{' '}
                &middot; {post.fields.readingTime.text} &middot; Last Updated:{' '}
                {formatDate(post.parent.modifiedTime, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </time>
            )}
            {post.frontmatter.showDefaultImage && (
              <div className='post__main-image'>
                <StaticImage
                  src='../../static/img/mihirpipermitwala-placeholder.jpg'
                  alt={data.site.siteMetadata.siteUrl + post.fields.slug}
                  layout='fullWidth'
                  width='100%'
                  style={{ borderRadius: '0.8em', border: '1em' }}
                  aspectRatio={1.5}
                  transformOptions={{
                    fit: 'cover',
                    cropFocus: 'attention',
                  }}
                  backgroundColor='#181818'
                />
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            {topics.length > 0 && (
              <>
                <span className='tags_title'>Tags:</span>
                <TagsTopicList topics={topics} />
              </>
            )}
            <CommentCount config={disqusConfig} placeholder={''} />
            <Disqus config={disqusConfig} />
          </article>
          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.posts' })}{' '}
              <Link to={getPath('blogs')}>{formatMessage({ id: 'stack.title' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Post
